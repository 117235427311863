import React, { useEffect, useState } from 'react';
import { MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';

function StarRewardSummary({ account, cart }) {
  const [isDesktopSize] = useMediaQuery(desktop);
  const [down, setDown] = useState(isDesktopSize);
  const [didUserIntervene, setDidUserIntervene] = useState(false);
  const { formatMessage } = useFormat({ name: 'cart' });

  const handleArrow = () => {
    setDidUserIntervene(true);
    setDown(!down);
  };

  useEffect(() => {
    if (!didUserIntervene) {
      setDown(isDesktopSize);
    }
  }, [isDesktopSize]);

  return (
    <>
      {cart?.custom?.fields?.containsMilStarPayment && (
        <section className="mb-5 rounded border border-gray-700 ">
          <section className="flex cursor-pointer justify-between bg-gray-700" onClick={handleArrow}>
            <h3 className=" p-2 text-affes-xl font-bold text-gray-900">
              {' '}
              {formatMessage({ id: 'militaryRewardSummary', defaultMessage: 'Military Star Reward Summary' })}
            </h3>

            {!isDesktopSize && (
              <section className="my-auto mr-3">
                {!down ? (
                  <MdOutlineKeyboardArrowDown onClick={handleArrow} className="text-3xl text-gray-900" />
                ) : (
                  <MdOutlineKeyboardArrowUp onClick={handleArrow} className="text-3xl text-gray-900" />
                )}
              </section>
            )}
          </section>

          {down && (
            <section>
              <section className="rewardSummary flex justify-between p-2 py-4 text-sm">
                <section className="flex-col ">
                  <p className="pb-2 text-gray-900">
                    {formatMessage({ id: 'pointEarnedPurchase', defaultMessage: 'Points earned on this purchase:' })}
                  </p>
                  <p className="text-gray-900">
                    {formatMessage({ id: 'pointToNextForward', defaultMessage: 'Points to earn next forward:' })}
                  </p>
                </section>
                <section className="flex-col text-right font-bold ">
                  <p className="pb-2 font-bold text-gray-900">{Math.round(cart?.cartTotal?.militaryStarReward)}</p>
                  <p className="font-bold text-gray-900">{Math.round(cart?.cartTotal?.nextRewardPoint)}</p>
                </section>
              </section>
              <section className="mx-2 mb-4">
                <section className="border-[1px solid rgba(0,0,0,0.2)] h-2.5 w-full rounded-full border">
                  <section
                    className="h-2.5  rounded-full bg-blue-900 "
                    style={{ width: cart?.cartTotal?.progress }}
                  ></section>
                </section>
              </section>
            </section>
          )}
        </section>
      )}
    </>
  );
}

export default StarRewardSummary;
