import React, { useEffect, useRef, useState } from 'react';
import Loader from 'components/commercetools-ui/loader/Loader';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { sendToMonetate } from 'helpers/utils/monetateHelper';
import { tablet } from 'helpers/utils/screensizes';
import { useCart } from 'frontastic';
import { getMarketingBugProducts } from 'frontastic/actions/product';
import BackToPLP from './backToPLP';
import ProductInfo from './productInfo';

const ProductTable = ({ products, skus }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const [processedSpecifications, setProcessedSpecifications] = useState([]);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [isDesktop] = useMediaQuery(tablet);
  const [gridStyle, setGridStyle] = useState({});
  const [isSticky, setIsSticky] = useState(false);
  const [scrollDivHeight, setScrollDivHeight] = useState();
  const [marketingBugProducts, setMarketingBugProducts] = useState([]);
  const tbodyRef = useRef(null);
  const { studioConfig, data, setCartProductDetailEventSent } = useCart();

  const handleGridTop = () => {
    if (products?.length < 4) return <div className="border-b border-r border-[#00000033]"></div>;
  };
  const handleGridBotton = () => {
    if (products?.length === 1) {
      const borderDivs = Array.from({ length: 2 }, (_, index) => (
        <div key={index} className="border-b border-[#00000033]"></div>
      ));
      return borderDivs;
    } else if (products?.length === 2) {
      return <div className="border-b border-[#00000033]"></div>;
    }
  };

  const findVariantsBySkus = (product) => {
    const variantMap = {};
    product?.variants?.forEach((variant) => {
      variantMap[variant.sku] = variant;
    });

    const matchingVariants = skus?.map((sku) => variantMap[sku]);
    const productWithMarketingBug = marketingBugProducts?.find((product) =>
      product?.variants?.find((variant) => variant?.skuId === matchingVariants?.filter(Boolean)[0]?.sku),
    );
    return { ...matchingVariants?.filter(Boolean), marketingBug: productWithMarketingBug?.marketingBug || null };
  };

  const handleStickybar = () => {
    return <div style={{ height: scrollDivHeight }}></div>;
  };
  useEffect(() => {
    const processSpecifications = async () => {
      const specificationsArray = [];

      products?.forEach(async (product, index) => {
        const matchingVariants = findVariantsBySkus(product);
        const specifications = matchingVariants?.[0]?.attributes?.specifications
          ? JSON?.parse(product?.variants?.[0]?.attributes?.specifications)
          : null;
        const description = product?.description;

        specifications &&
          specifications?.forEach((spec) => {
            const { label, desc } = spec;
            const key = label;

            const existingSpec = specificationsArray?.find((item) => item?.key === key);

            if (!existingSpec) {
              const newEntry = { key, value: [] };
              for (let i = 1; i <= products.length; i++) {
                newEntry.value.push({ [`product${i}`]: i === index + 1 ? desc : null });
              }
              specificationsArray.push(newEntry);
            } else {
              const productIndex = index + 1;
              const productEntry = existingSpec.value.find((entry) => entry.hasOwnProperty(`product${productIndex}`));

              if (productEntry) {
                productEntry[`product${productIndex}`] = desc;
              } else {
                existingSpec.value.push({ [`product${productIndex}`]: desc });
              }
            }
          });
        const descriptionKey = 'Description';
        const existingDescription = specificationsArray?.find((item) => item?.key === descriptionKey);

        if (!existingDescription) {
          specificationsArray.push({ key: descriptionKey, value: [{ [`product${index + 1}`]: description }] });
        } else {
          const productIndex = index + 1;
          const productEntry = existingDescription.value.find((entry) =>
            entry.hasOwnProperty(`product${productIndex}`),
          );

          if (productEntry) {
            productEntry[`product${productIndex}`] = description;
          } else {
            existingDescription.value.push({ [`product${productIndex}`]: description });
          }
        }
      });

      setProcessedSpecifications(specificationsArray);
    };

    processSpecifications();
  }, [products]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoad(true);
        const formattedSkus = skus.join(',');
        const res = await getMarketingBugProducts(formattedSkus);
        setMarketingBugProducts(res?.records);
        setIsLoad(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoad(false);
      }
    };

    fetchData();
  }, [skus]);

  useEffect(() => {
    const initBazaarvoice = () => {
      if (window.$BV) {
        clearInterval(intervalId);
        const productIds = products?.map((product) => product?.productId);
        window.$BV.ui('rr', 'inline_ratings', {
          productIds: productIds,
          containerPrefix: 'BVRRInlineRating',
        });
      }
    };
    const intervalId = setInterval(() => {
      initBazaarvoice();
    }, 500);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [products]);
  const [monetateConfig, setMonetateConfig] = useState(null);
  const [monetateSent, setMonetateSent] = useState(false);
  useEffect(() => {
    if (studioConfig?.monetateConstants) {
      try {
        const monetateConfig = JSON?.parse(studioConfig?.monetateConstants?.replace(/'/g, '"'));
        setMonetateConfig(monetateConfig);
      } catch (error) {
        console.error('Errorparsing projectConfig:', error);
      }
    }
  }, [studioConfig]);

  useEffect(() => {
    const processMonetate = async () => {
      const callMonetate = async () => {
        setMonetateSent(true);
        await sendToMonetate(
          {
            page: 'compare',
            cart: {
              ...data,
            },
          },
          monetateConfig,
        );
      };
      if (data && monetateConfig && !monetateSent) {
        await callMonetate();
        setCartProductDetailEventSent(true);
      }
    };
    const fetchData = async () => {
      await processMonetate();
    };

    fetchData();
  }, [data, monetateConfig]);

  return (
    <>
      <BackToPLP />
      <div className="mb-4 overflow-x-scroll lg:overflow-x-auto">
        <table className="min-w-full md:w-[1024px] lg:w-full">
          <thead className="sticky top-0 bg-white">
            <ProductInfo
              products={products}
              handleGridTop={handleGridTop}
              handleGridBotton={handleGridBotton}
              findVariantsBySkus={findVariantsBySkus}
              setIsLoad={setIsLoad}
              tbodyRef={tbodyRef}
              gridStyle={gridStyle}
              setGridStyle={setGridStyle}
              isSticky={isSticky}
              setIsSticky={setIsSticky}
              setScrollDivHeight={setScrollDivHeight}
            />
            {isSticky && <tr>{handleStickybar()}</tr>}
          </thead>
          <tbody ref={tbodyRef}>
            <tr className="text-lg font-bold leading-5 text-gray-900">
              <td className="py-4">
                {' '}
                {formatMessage({ id: 'productSpecification', defaultMessage: 'Product Specification' })}
              </td>
            </tr>
            {processedSpecifications?.map((Specification, index) => {
              return (
                <tr key={index} className="min-w-full text-gray-900">
                  <div className="flex min-h-[36px] w-full items-center bg-[#F5F5F5] px-1 text-sm font-bold leading-5">
                    {Specification?.key}
                  </div>
                  <div style={{ ...gridStyle }}>
                    {isDesktop && handleGridTop()}
                    {Specification?.value?.map((val, index) => {
                      return (
                        <td
                          key={index}
                          className="flex min-h-[44px] flex-col items-center justify-center border-b border-r border-[#00000033] p-2 text-center text-sm leading-5"
                          dangerouslySetInnerHTML={{ __html: val[`product${index + 1}`] }}
                        ></td>
                      );
                    })}
                    {isDesktop && handleGridBotton()}
                  </div>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {isLoad && <Loader />}
    </>
  );
};

export default ProductTable;
