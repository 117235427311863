import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import cookieCutter from 'cookie-cutter';
import Loader from 'components/commercetools-ui/loader/Loader';
import { PAGE_VIEW } from 'helpers/constants/aafes';
import useGATrackViewActions from 'helpers/hooks/gaEvents/useGATrackViewActions';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { sendToMonetate } from 'helpers/utils/monetateHelper';
import { tablet } from 'helpers/utils/screensizes';
import { useAccount, useCart } from 'frontastic';
import Breadcrumb from './breadcrumb';
import CLPSection from './clpSection';
import CompareProducts from './compareSection';
import Contents from './contents';
import Filter from './filter';
import FiltersApplied from './filtersApplied';
import PageView from './pageView';
import Pagination from './pagination';
import ProductTile from './productTile';
import SortBy from './sortBy';

export default function PLPCLPIndex({ res }) {
  const { formatMessage } = useFormat({ name: 'common' });
  const [isDesktop, currentWidth] = useMediaQuery(tablet);
  const [selectedRefinements, setSelectedRefinements] = useState([]);
  const [isFilterclicked, setIsFilterclicked] = useState(false);
  const [isMobileFilter, setIsMobileFilter] = useState<boolean>(false);
  const [selectedPageView, setSelectedPageView] = useState<number>(PAGE_VIEW?.desktopView?.[0]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(true);
  const { trackViewItemList } = useGATrackViewActions();
  const { account } = useAccount();
  const [isShowRefinements, setIsShowRefinements] = useState([]);
  const [compareProducts, setCompareProducts] = useState([]);
  const router = useRouter();

  const filterProperties = {
    data: res?.filters,
    setSelectedRefinements,
    selectedRefinements,
    setIsFilterclicked,
    isFilterclicked,
    isMobileFilter,
    setIsMobileFilter,
    setIsShowRefinements,
    isShowRefinements,
  };

  const handleFilter = () => {
    return (
      <>
        {isFilterclicked && <div className="fixed inset-0 h-full w-full bg-[#303030]" />}
        <div className={`${isFilterclicked && 'fixed inset-0 overflow-y-auto transition-all duration-300'} h-full`}>
          <div className={`${isFilterclicked && 'flex items-center justify-center'} h-full`}>
            <section
              onClick={() => {
                setIsMobileFilter((prev) => !prev);
                setIsFilterclicked((prev) => !prev);
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setIsMobileFilter((prev) => !prev);
                  setIsFilterclicked((prev) => !prev);
                }
              }}
              role="none"
              tabIndex={0}
              className={`${isFilterclicked && 'absolute inset-0'} h-full`}
            >
              <section
                className={`${
                  isFilterclicked && 'absolute h-full w-[90%] overflow-y-auto bg-white dark:bg-primary-200'
                } h-full`}
                onClick={(e) => e?.stopPropagation()}
              >
                <Filter filterProperties={filterProperties} setCurrentPage={setCurrentPage} />
              </section>
            </section>
          </div>
        </div>
      </>
    );
  };

  const productCount = () => {
    return (
      <p className="my-3 h-[1.375rem] text-lg font-bold leading-5 text-gray-900 md:m-0">
        {res?.total} {formatMessage({ id: 'items', defaultMessage: 'Items' })}
      </p>
    );
  };
  const handleCompareButtonClick = (variant) => {
    let updatedCompareProducts;
    const setProductCookies = () => {
      setCompareProducts(updatedCompareProducts);
      cookieCutter.set('compareProducts', JSON.stringify(updatedCompareProducts));
    };
    if (compareProducts?.some((item) => item.skuId === variant?.skuId)) {
      // Remove the variant if it's already in the comparison list
      updatedCompareProducts = compareProducts?.filter((item) => item.skuId !== variant?.skuId);
      setProductCookies();
    } else {
      if (compareProducts?.length < 4) {
        updatedCompareProducts = [...compareProducts, variant];
        setProductCookies();
      }
    }
  };

  useEffect(() => {
    if (cookieCutter.get('compareProducts')) {
      const storedCompareProducts = JSON.parse(cookieCutter.get('compareProducts') || '[]');
      if (storedCompareProducts?.length > 0) {
        setCompareProducts(storedCompareProducts);
      }
    }
  }, []);

  useEffect(() => {
    if (isFilterclicked && !isDesktop) {
      if (window?.document) {
        document.body.style.overflow = 'hidden';
      }
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [isFilterclicked, isDesktop]);

  useEffect(() => {
    const URL_DATA = router?.query?.facets;

    if (URL_DATA) {
      const decodedFacet = Array.isArray(URL_DATA) ? URL_DATA[0] : URL_DATA;
      try {
        const decodedFacets = decodeURIComponent?.(decodedFacet);
        const facetsObject = JSON.parse(decodedFacets);
        setSelectedRefinements(facetsObject);
      } catch (error) {
        console.error('Error decoding and parsing facets:', error);
      }
    } else setSelectedRefinements([]);
  }, [router?.asPath]);

  useEffect(() => {
    const URL_DATA = router?.query?.pageSize;
    if (URL_DATA) {
      const decodedpageSize = Array.isArray(URL_DATA) ? URL_DATA[0] : URL_DATA;
      try {
        setIsLoading(true);
        setSelectedPageView(JSON.parse(decodedpageSize));
        setIsLoading(false);
      } catch (error) {
        console.error('Error decoding and parsing PageSize:', error);
      }
    } else {
      setSelectedPageView(PAGE_VIEW?.desktopView?.[0]);
    }
  }, [router?.asPath]);

  useEffect(() => {
    trackViewItemList({ res, account });
  }, [res, account]);

  useEffect(() => {
    res?.isCLP && setIsShowRefinements([]);
  }, [res?.isCLP]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 250);
  }, []);

  if (isLoading || currentWidth === 0) {
    return <Loader />;
  }

  return (
    <>
      <div className="mx-auto">
        {isDesktop && <Breadcrumb selectedFilters={res?.selectedFilters} query={res?.query} />}
        <div className="gap-x-5 md:flex md:flex-row xl:gap-x-10">
          {isDesktop && (
            <div className="w-full text-sm font-normal md:w-[22%] md:min-w-[198px]">
              <Filter filterProperties={filterProperties} setCurrentPage={setCurrentPage} contents={res?.cmsContent} />
            </div>
          )}
          {res?.isCLP ? (
            <main role="main" className="w-full pt-1">
              <CLPSection contents={res?.cmsContent} handleFilter={handleFilter} isFilterclicked={isFilterclicked} />
              <section>
                <div className="min-h-24 fixed bottom-0 left-0 z-[9] mb-0 w-full bg-[#F1F1F1F2] shadow-[0px_-3px_4px_0px_#00000040]">
                  <CompareProducts compareProducts={compareProducts} setCompareProducts={setCompareProducts} />
                </div>
              </section>
            </main>
          ) : (
            <main role="main" className="w-full pt-1">
              {res?.cmsContent && <Contents contents={res?.cmsContent} />}
              {res?.query && res?.query !== '' && (
                <p className="mb-3 w-fit text-[21px] font-bold leading-6 text-gray-900">
                  {formatMessage({ id: 'searchResults', defaultMessage: 'Search results for : ' })}
                  {res?.query}
                </p>
              )}
              {!isDesktop ? (
                <>
                  <div className={`grid grid-cols-2 gap-x-2 text-sm font-normal`}>
                    <div className={`${isFilterclicked && 'relative z-[1000] w-full'} h-full`}>{handleFilter()}</div>
                    <SortBy setCurrentPage={setCurrentPage} />
                  </div>
                  {productCount()}
                </>
              ) : (
                <div className="flex items-center justify-between">
                  {productCount()}
                  <div className="flex gap-x-2">
                    <SortBy setCurrentPage={setCurrentPage} />
                    <PageView
                      pageViewData={PAGE_VIEW?.desktopView}
                      setSelectedPageView={setSelectedPageView}
                      selectedPageView={selectedPageView}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                </div>
              )}
              <FiltersApplied
                selectedRefinements={selectedRefinements}
                setSelectedRefinement={setSelectedRefinements}
                filter={res?.filters}
                setIsShowRefinements={setIsShowRefinements}
              />
              <ProductTile
                products={res?.records}
                totalCount={res?.total}
                selectedPageView={selectedPageView}
                handleCompareButtonClick={handleCompareButtonClick}
                compareProducts={compareProducts}
                setCompareProducts={setCompareProducts}
              />
            </main>
          )}
        </div>
        <Pagination pageCount={res?.pageCount} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </div>
    </>
  );
}
