import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useFormat } from 'helpers/hooks/useFormat';
import { fetchApiHub, revalidateOptions } from 'frontastic';
import EditIcon from '../../../icons/editPencil';
import PickupPersonModal from '../Modals/pickUpPerson';
function PickUpDetails({ item, isOrderConfirmed }) {
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const [showModal, setShowModal] = useState(false);
  const [editShowModal, setEditShowModal] = useState(false);
  const [pickUpMsg, setPickUpMsg] = useState('');
  const isAgeRestricted =
    item?.variant?.attributes?.ageRestriction !== undefined && item?.variant?.attributes?.ageRestriction !== null;
  const [pickUpPersonDetails, setPickUpPersonDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  });

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditShowModal(false);
  };

  const queryParams = new URLSearchParams({
    messageKey: 'pickUpEmailMessage',
    values: JSON.stringify([item?.storeCutoffHour]),
  });
  const { data } = useSWR(`/action/customObjects/getCustomMessage?${queryParams}`, fetchApiHub, revalidateOptions);

  useEffect(() => {
    const getPickUpMsg = async () => {
      try {
        setPickUpMsg(data);
      } catch (error) {
        console.error('Error fetching or parsing data:', error);
      }
    };
    if (!item?.isShipToStore) {
      getPickUpMsg();
    }
  }, [data]);
  return (
    <>
      <section className="mt-5 rounded-sm border border-[rgba(153,153,153,0.3)] py-3 px-[13px] xl:w-[657px]">
        <h4 className="mb-1 text-affes-sm font-bold text-gray-800">
          {formatCheckoutMessage({ id: 'pickUp.details', defaultMessage: 'Pick Up Details' })}
        </h4>
        <section className={`lg:flex ${item?.isShipToStore ? '' : 'flex-col'}`}>
          {item?.isShipToStore ? (
            <p className="mr-[10px] text-affes-sm text-gray-900">
              {formatCheckoutMessage({
                id: 'we.will.mail.you',
                defaultMessage: 'We will email you when the order is ready for pick up.',
              })}
            </p>
          ) : (
            <p className="mr-[10px] text-affes-sm text-gray-900">{pickUpMsg}</p>
          )}
          {item?.pickupPersonFirstName ? (
            <>
              <p className="text-affes-sm text-accent-400">
                {item?.pickupPersonFirstName}{' '}
                {formatCheckoutMessage({
                  id: 'pickUpPersonInformation',
                  defaultMessage: ' will pick up this Product.',
                })}
                {!isOrderConfirmed && (
                  <button
                    className="ml-2"
                    onClick={() => {
                      setEditShowModal(true);
                    }}
                  >
                    <EditIcon />
                  </button>
                )}
              </p>
            </>
          ) : (
            <>
              {!isOrderConfirmed && !isAgeRestricted && (
                <>
                  <p className="cursor-pointer text-affes-sm text-accent-400" onClick={openModal}>
                    {formatCheckoutMessage({
                      id: 'someone.else.pickup',
                      defaultMessage: 'Will someone else pick it up?',
                    })}
                  </p>
                </>
              )}
            </>
          )}
        </section>
      </section>
      {editShowModal && (
        <PickupPersonModal
          item={item}
          pickUpDetails={setPickUpPersonDetails}
          closeModal={closeModal}
          editPickUpDetails={true}
        />
      )}
      {showModal && (
        <PickupPersonModal
          item={item}
          pickUpDetails={setPickUpPersonDetails}
          closeModal={closeModal}
          editPickUpDetails={false}
        />
      )}
    </>
  );
}

export default PickUpDetails;
