import NavItem from 'components/commercetools-ui/aafes/brands';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
interface Brand {
  name: string;
  url: string;
}
const BrandsListing = (props: any) => {
  const categoryList = props?.data?.categoryData?.dataSource?.root?.children;
  const router = useRouter();
  const { N = 'all' } = router?.query;
  const currentCategory = N;
  const handleCategorySelect = useCallback((categoryId) => {
    router.replace(`/brands?N=${categoryId}`, undefined, { shallow: true });
  }, []);
  const RenderBrands = () => {
    let brands: Brand[] = [];
    const brandsSet = new Set<string>();
    let selectedCategory: any = {};
    if (currentCategory === 'all') {
      categoryList?.forEach((cat: any) => {
        cat?.brands?.forEach((brand: any) => brandsSet.add(brand));
        cat?.children?.forEach((sub: any) => sub?.brands?.forEach((brand: any) => brandsSet.add(brand)));
      });
    } else {
      const l1SelectedCategory = categoryList?.filter((cat: any) => {
        return cat?.categoryId === currentCategory;
      })?.[0];
      if (l1SelectedCategory) {
        selectedCategory = l1SelectedCategory;
        l1SelectedCategory?.brands?.forEach((brand: any) => brandsSet.add(brand));
        l1SelectedCategory?.children?.forEach((sub: any) => sub?.brands?.forEach((brand: any) => brandsSet.add(brand)));
      } else {
        const l2SelectedCategory = categoryList
          ?.flatMap((cat: any) => cat?.children || [])
          .filter((childCat: any) => {
            return childCat?.categoryId === currentCategory;
          })?.[0];
        selectedCategory = l2SelectedCategory;
        l2SelectedCategory?.brands?.forEach((brand: any) => brandsSet.add(brand));
      }
    }
    brands = Array.from(brandsSet)
      .map((brand) => {
        let url;
        const facets = {
          brands: [brand],
        };
        const encodedFacets = encodeURIComponent(JSON?.stringify?.(facets));

        if (selectedCategory?.url) {
          const urlFormat = new URL(`${window?.location?.origin}${selectedCategory.url}`);
          urlFormat.searchParams.set('facets', encodedFacets);
          url = urlFormat.toString();
        } else {
          const CURRENT_URL = window?.location?.origin;
          const urlFormat = new URL(CURRENT_URL);
          urlFormat.pathname = '/browse';
          urlFormat.searchParams.set('facets', encodedFacets);
          url = urlFormat.toString();
        }
        return { name: brand?.trim(), url };
      })
      .sort((a, b) => a?.name?.localeCompare(b?.name));
    return brands?.filter((brand) => brand?.name);
  };
  return (
    <nav>
      {
        <NavItem
          categories={categoryList}
          brands={RenderBrands()}
          handleCategorySelect={handleCategorySelect}
          currentCategory={currentCategory}
        />
      }
    </nav>
  );
};

export default BrandsListing;
