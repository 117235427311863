import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M7.2 12.8H8.8V11.2H7.2V12.8ZM8 0C6.94943 0 5.90914 0.206926 4.93853 0.608964C3.96793 1.011 3.08601 1.60028 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 6.94943 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4ZM8 3.2C7.15131 3.2 6.33737 3.53714 5.73726 4.13726C5.13714 4.73737 4.8 5.55131 4.8 6.4H6.4C6.4 5.97565 6.56857 5.56869 6.86863 5.26863C7.16869 4.96857 7.57565 4.8 8 4.8C8.42435 4.8 8.83131 4.96857 9.13137 5.26863C9.43143 5.56869 9.6 5.97565 9.6 6.4C9.6 8 7.2 7.8 7.2 10.4H8.8C8.8 8.6 11.2 8.4 11.2 6.4C11.2 5.55131 10.8629 4.73737 10.2627 4.13726C9.66263 3.53714 8.84869 3.2 8 3.2Z"
      fill="#043C63"
    />
  </svg>
);

export default Icon;
