import React from 'react';
import Icon from 'components/icons/infoIcon';
import ExchangePlusImage from 'components/images/exchangePlusIcon.png';
import MegaMenuArrow from 'components/images/megamenu-arrow.png';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';

function ExchangePlusInfo() {
  const { studioConfig: projectConfiguration } = useCart();
  const exchangePlusData = projectConfiguration?.exchangePlusPartner;
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  return (
    <>
      <section className="mt-2 flex items-center justify-center px-3 text-sm font-normal text-gray-900">
        <img src={ExchangePlusImage.src} className="mr-2 inline" width={22} height={22} alt="Exchange Plus" />
        <span className="leading-[16.8px]">
          {formatProductMessage({ id: 'exchangePlusPartner', defaultMessage: 'Exchange Plus Partner' })}
        </span>
        <div className="details-popover hidden" id="set"></div>
        <div className="group relative">
          <button
            className="text-primary ml-2 mt-2 cursor-pointer text-lg text-blue-900"
            onMouseEnter={(event) => {
              const popupScroll = event?.currentTarget?.nextElementSibling?.querySelector('.popup-scroll');
              if (popupScroll && popupScroll?.scrollTop !== 0) {
                popupScroll.scrollTop = 0;
              }
            }}
          >
            <Icon />
          </button>
          <div className="text-black -top-30 invisible absolute right-8 bottom-5 z-10 transition-all duration-300 group-hover:visible group-hover:opacity-100">
            <div className="w-[14rem] translate-x-1/2 rounded border border-solid bg-white text-sm">
              <div className="flex border border-solid bg-gray-700 px-3 py-2 font-semibold text-slate-500">
                {formatProductMessage({ id: 'exchangePlus', defaultMessage: 'Exchange Plus' })}
              </div>
              <div className="popup-scroll h-[20rem] overflow-y-auto p-2">
                <p className="px-2 font-semibold text-blue-900">
                  {formatProductMessage({
                    id: 'exchangePlusDetails',
                    defaultMessage: 'Exchange Plus Partners Details',
                  })}
                </p>
                <div className="p-2 text-gray-600" dangerouslySetInnerHTML={{ __html: exchangePlusData }} />
              </div>
            </div>
            <div className="boxArrow translate-x-9 rotate-180">
              <img src={MegaMenuArrow.src} alt="Mega Menu Arrow Icon" className="w-[24px] " />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ExchangePlusInfo;
