import React, { useEffect, useState } from 'react';
import OrderSummary from 'components/commercetools-ui/aafes/cart/orderSummary';
import { useAccount } from 'frontastic';
const OrderSummaryTastics = ({ onSubmit, data, getAllCustomMessages }) => {
  const [isOrderConfirmed, setIsOrderConfirmed] = useState(true);
  const [cardInfo, setCardInfo] = useState(null);
  const [orderData, setOrderData] = useState();
  const [giftCardsApplied, setGiftCardsApplied] = useState<any>({});
  const { orderConfirmationDetails } = useAccount();
  const handleOrderDetails = async () => {
    setOrderData(orderConfirmationDetails?.[0]);
    const creditCardPayment = orderConfirmationDetails?.[0]?.payments?.find(
      (payment) => payment.paymentMethod === 'creditCard',
    );
    setCardInfo(creditCardPayment?.cardType);
    const giftCardPayments = orderConfirmationDetails?.[0]?.payments?.filter(
      (payment) => payment.paymentMethod === 'giftCard',
    );
    if (giftCardPayments?.length) {
      let giftSum = 0;
      for (const payment of giftCardPayments) {
        giftSum += payment.amountPlanned.centAmount;
      }
      setGiftCardsApplied({
        centAmount: -giftSum,
        currencyCode: 'USD',
        fractionDigits: 2,
      });
    } else {
      setGiftCardsApplied({});
    }
  };
  useEffect(() => {
    handleOrderDetails();
  }, [orderConfirmationDetails]);
  return (
    <OrderSummary
      cart={orderData}
      onSubmit={onSubmit}
      isOrderConfirmed={isOrderConfirmed}
      isCheckout={false}
      giftCardsApplied={giftCardsApplied}
      placeOrderClicked={() => {}}
      cardType={cardInfo}
      itemQty={() => {}}
      isOrderConfirmation={true}
      itemInStock={() => {}}
      setProceedToCheckoutError={null}
      isCardErrorMsg={null}
      getAllCustomMessages={getAllCustomMessages}
    />
  );
};

export default OrderSummaryTastics;
